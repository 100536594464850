$default_font_face: serif;

/* # Colors
   ========================================= */

$solarized_base03: #002b36;
$solarized_base1: #93a1a1;
$solarized_base2: #eee8d5;
$solarized_base3: #fdf6e3;
$solarized_yellow: #b58900;
$button_category: hsl(322,51%,36%);
$button_tag: hsl(333,80%,50%);
$button_comment: hsl(28,100%,50%);
