html { font-size: 16px; } // define “root-em” (rem) size

body {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAAE0lEQVQIW2N48+bNfxhggLPwcgDQbi+YlZXTEQAAAABJRU5ErkJggg==');
  position: relative;
  min-height: 100vh;

  // text styling
  @include fontface;
  @include fontweight;
  overflow-wrap: break-word; // force wider-than-viewport words to break
  line-height: 1.5;

  font-size: 18px;
}

h1 { @include heading(2); }

h2 { @include heading(1.6); @include edge($color: #ddd, $distance: 0.5rem); }

h3 { @include heading(1.4); }

h4 { @include heading(1.2); }

hr { display: none; }

a {
  color: #22953c; @include underline(#ddd);
  &:visited { @include underline(#fff); }
  &:hover   { @include underline(#96dba5); }
  &:active  { @include highlight(#40867e) }
}

b, strong { @include fontweight(bold); }

dfn { @extend b; font-style: normal; }

mark {
  @include fontweight(bold);
  @include highlight(#33a84d);
  padding: 0 0.25rem;
}

li {
  margin: 0.5rem 0;
  line-height: 1.25;
  p, pre, ol, ul, blockquote { margin: 0.75rem 0; }
}

code {
  @include fontface(monospace);
  @include underline($solarized_yellow);
  background-color: $solarized_base3;
  padding: 0.0625em 0.25em;
  font-size: 0.875em;
  border-radius: 0.25rem;

  @include tablet-portrait-up { white-space: nowrap; } // wrap on small screens
}

pre { // code blocks
  @include inset;
  font-size: 0.875rem;

  code { // override inline <code> rules
    padding: 0;
    font-size: inherit;
    white-space: pre;
    color: inherit;
    background-color: transparent;
    border: none;
  }
}

blockquote {
  @include fontface(serif);
  font-style: italic;
  font-size: 0.9375rem;
  border: 1px dotted $solarized_yellow;
  @include edge(left, 0.5rem, $solarized_yellow);
  @include inset($background-color: $solarized_base3);
  @include mobile-only { margin: 0; } // override browser default

  em, cite, i { font-style: normal; }

  mark { background-color: $solarized_yellow; color: $solarized_base3; }

  code { background-color: $solarized_base2; }

  a:visited { @include underline($solarized_base3); }

}
